import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  height: 100vh;
`

const FullHeaderColorBg = ({ children }) => (
  <StyledDiv className='flex items-center justify-center bg-white sm:p-4 py-12'>
    {children}
  </StyledDiv>
)

FullHeaderColorBg.propTypes = {
  children: PropTypes.node,
}

export default FullHeaderColorBg
import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import FullHeaderColorBg from '../components/headers/FullHeaderColorBg'
import HeaderNav from '../components/navigation/HeaderNav'
import HomeHeroText from "../components/headers/HomeHeroText"
import HomeAboutSection from "../components/sections/HomeAboutSection"

const IndexPage = () => (
  <HomeContainer>
    <HeadData isHome />

    <FullHeaderColorBg>
      <HeaderNav isHome />
      <HomeHeroText />
    </FullHeaderColorBg>

    <HomeAboutSection />
  </HomeContainer>
)

export default IndexPage

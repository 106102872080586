import React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const HomeHeroText = () => {
  return (
    <StyledDiv className='sm:w-full w-10/12 xl:w-7/12 flex sm:flex-col items-center mt-24'>
      <div className='sm:w-full w-1/2 flex flex-col sm:items-center sm:mb-8'>
        <h1 className='font-display text-6xl sm:text-4xl text-blue-900 sm:text-center uppercase leading-tight mb-6'>
          Brotherhood Beyond College
        </h1>

        <h2 className='font-body text-lg text-gray-900 mb-6 sm:text-center'>
          Our alumni chapter serves ANY and ALL alumni who live in, around or are visiting Orange County California regardless of where they went to school. We welcome ALL Sig alumni no matter if you went to school on the east coast, west coast, in the Midwest or the South.
        </h2>

        <Link to='/contact'>
          <button className="bg-orange-600 hover:bg-orange-500 text-white text-lg font-bold py-3 px-6 rounded">
            Get In Touch
          </button>
        </Link>
      </div>

      <div className='sm:w-full w-1/2 flex items-center justify-center sm:hidden'>
        <StaticImage
          src="../../images/header/norman-shield.png"
          width={495}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Norman shield"
          className="w-7/12"
        />
      </div>
    </StyledDiv>
  )
}

export default HomeHeroText
import React from 'react'
import { Link } from 'gatsby'

const Parag = 'text-gray-800 text-lg font-body mb-4 text-center'

const HomeAboutSection = () => (
  <div className='bg-orange-100 px-16 py-24 sm:px-4 sm:py-12'>
    <div className='flex flex-col sm:w-full md:w-10/12 w-7/12 xl:w-5/12 items-center font-body justify-center mx-auto'>
      <h2 className='font-display text-5xl sm:text-4xl text-blue-900 text-center mb-6 leading-tight'>The Orange County Sigma Chi Alumni Chapter</h2>

      <p className={Parag}>With strong chapter leadership and a large loyal base of alumni members, we continue to provide exciting events and innovative programs to benefit all local Sigs.</p>

      <p className={Parag}>As our fraternity strives to promote friendship, justice, and learning as the exemplification of our brotherhood and inspires a lifelong commitment to serve our families, friends, and the communities in which we live and work, so is the goal of the Orange County Sigma Chi Alumni Chapter. If you're a Sig and you're interested in community involvement, undergraduate involvement, philanthropic service, personal and professional development or social interaction, get involved today!</p>

      <Link to='/contact'>
        <button className="bg-orange-600 hover:bg-orange-500 text-white text-lg font-bold mt-4 py-3 px-6 rounded">
          Get In Touch
        </button>
      </Link>
    </div>
  </div>
)

export default HomeAboutSection